// Black
@font-face {
  font-family: Satoshi;
  src:
    url('../../public/fonts/Satoshi-Black.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

// Bold
@font-face {
  font-family: Satoshi;
  src:
    url('../../public/fonts/Satoshi-Bold.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// Medium
@font-face {
  font-family: Satoshi;
  src:
    url('../../public/fonts/Satoshi-Medium.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
