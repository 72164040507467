@mixin responsive($min, $max) {
  @media screen and (min-width: #{$min}) and (max-width: #{$max}) {
    @content;
  }
}

@mixin touchable() {
  transition: all 0.2s ease-in-out;

  @media (hover: none) {
    transition-duration: 0.1s;
  }

  &:hover {
    transform: scale(1.05, 1.05);
  }

  &:active {
    transform: scale(0.9, 0.9);
  }
}
