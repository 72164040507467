/*  ========================================================================
    # Components - Header
    ========================================================================  */

.lazy-image {
  width: 100%;
  overflow: hidden;
  background-color: transparent;

  // transition: background-color 0.15s ease;

  &__img {
    width: 100%;
    filter: blur(0);

    &[data-rotation='left'] {
      transform: rotateZ(-90deg);
    }

    &[data-rotation='right'] {
      transform: rotateZ(90deg);
    }

    &[data-rotation='upsidedown'] {
      transform: rotateZ(180deg);
    }

    // transition: filter 0.15s ease;
  }

  &--pending {
    position: relative;
    background-color: #868686;

    .lazy-image__img {
      height: auto;
      max-height: 100%;
      filter: blur(30px);
      transform: scale(1.1);
    }
  }
}
