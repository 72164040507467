@import '../global/shared';

.ParticipantBubble {
  width: 100%;
  text-decoration: none;
  max-width: 150px;
  margin: 0 auto;

  @include touchable;

  &__ImageWrapper {
    width: 100%;

    aspect-ratio: 1 / 1;
    position: relative;
    overflow: hidden;
    margin: 0 0 10px;
    border-radius: 50%;
    transform: translateZ(0);
  }

  &__Image {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  &__Name {
    @include font--medium;
    @include fontsize--normal;
    text-align: center;
    color: $color__purple;
  }
}
