@import '../global/shared';

.ErrorBoundary {
  padding: 80px 0 50px;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;

  &__Icon {
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto 20px;
    fill: $color__purple;
  }

  &__Title {
    margin-bottom: 20px;
  }

  &__Message {
    margin-bottom: 40px;
  }

  &__Reload {
    // Reset
    border: none;
    box-shadow: none;
    outline: none !important;
    margin: 0;

    @include font--bold;
    @include fontsize--small;
    @include touchable;
    text-decoration: none;
    color: $color__purple;
    text-align: center;
    padding: 14px 15px 16px;
    background: $color__yellow;
    border-radius: 500px;
    cursor: pointer;

    @media (hover: hover) {
      &:hover,
      &:active {
        color: $color__yellow;
        background: $color__purple;
      }
    }
  }
}
