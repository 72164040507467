@import '../global/shared';

.Detail {
  padding: 30px 0 50px;

  &__Content {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }

  &__Image {
    width: 164px !important;
    height: 164px !important;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    margin: 0 auto 15px;
  }

  &__Name {
    margin-bottom: 5px;
  }

  &__Company {
    @include font--bold;
    @include fontsize--normal;
    margin-bottom: 10px;
  }

  &__Hashtags {
    @include font--bold;
    @include fontsize--normal;
    color: $color__blue;
  }

  &__Divider {
    border: none;
    display: block;
    margin: 20px auto;
    height: 1px;
    width: 110px;
    background: $color__purple;
  }

  &__QuoteTitle,
  &__SocialTitle {
    @include font--bold;
    margin-bottom: 16px;
  }

  &__Social {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__SocialLink {
    @include touchable;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $color__purple;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
  }

  &__SocialIcon {
    fill: $color__gray;
  }
}
