@import 'shared';

body {
  @include font--medium;
  @include fontsize--normal;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $color__gray;
  color: $color--purple;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0%);
  -webkit-tap-highlight-color: transparent;
}

.loading-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: $color__gray;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 1;
  visibility: visible;

  body.initialized & {
    transition: opacity 500ms ease-in-out, visibility 500ms ease-in-out;
    opacity: 0;
    visibility: hidden;
    transition-delay: 500ms;
  }

  svg {
    width: 100px;
    height: 100px;
    transition: all 250ms ease-in;

    body.initialized & {
      transform: scale(0.5, 0.5);
      opacity: 0;
    }

    circle {
      fill: $color__purple;
    }
  }
}
