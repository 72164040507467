@import '../global/shared';

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__Button {
    @include font--bold;
    @include fontsize--small;
    @include touchable;
    text-decoration: none;
    color: $color__purple;
    width: 105px;
    text-align: center;
    padding: 14px 15px 16px;
    background: $color__yellow;
    border-radius: 500px;

    @media (hover: hover) {
      &:hover,
      &:active {
        color: $color__yellow;
        background: $color__purple;
      }
    }
  }

  &__LogoLink {
    font-size: 0;
    line-height: 1;
  }
}
