h1 {
  @include font--black;
  @include fontsize--headline;
  margin: 0;
  padding: 0;
  text-align: center;
}

p {
  @include font--medium;
  @include fontsize--normal;
  margin: 0;
  padding: 0;
}
