@import '../global/shared';

.SearchBar {
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  &__InputWrapper {
    position: relative;
    flex: 1 1 auto;
  }

  &__Input {
    // Reset
    border: none;
    background-image: none;
    box-shadow: none;
    outline: none !important;
    margin: 0;

    @include font--bold;
    @include fontsize--small;
    background-color: $color__white;
    color: $color__purple;
    padding: 7px 60px 7px 12px;
    border-radius: 5000px 0 0 5000px;
    width: 100%;
  }

  &__Reset {
    // Reset
    border: none;
    background: none;
    box-shadow: none;
    outline: none !important;
    margin: 0;

    @include touchable;
    position: absolute;
    width: 30px;
    height: 30px;
    right: 5px;
    top: 50%;
    margin-top: -15px;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.15s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &--Visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__ResetIcon {
    width: 12px;
    fill: $color__purple;
  }

  &__Submit {
    // Reset
    border: none;
    background-image: none;
    box-shadow: none;
    outline: none !important;
    margin: 0;

    @include touchable;
    @include font--bold;
    @include fontsize--small;
    background-color: $color__purple;
    color: $color__white;
    padding: 7px 30px;
    border-radius: 0 5000px 5000px 0;
    flex: none;
    cursor: pointer;

    @media (hover: hover) {
      &:hover,
      &:active {
        color: $color__purple;
        background: $color__yellow;
      }
    }
  }
}
