@import '../global/shared';

$switch-duration: 150ms;

.App {
  padding: 20px 25px;
  max-width: 1200px;
  margin: 0 auto;

  &__View--Fade {
    &-Left,
    &-Right {
      &-enter-active {
        transition: opacity $switch-duration ease-out, transform $switch-duration ease-out;
      }

      &-exit-active {
        transition: opacity $switch-duration ease-in, transform $switch-duration ease-in;
      }
    }

    &-Left {
      &-enter,
      &-exit-active {
        opacity: 0;
        transform: translateX(-50vw);
      }

      &-exit {
        opacity: 1;
        transform: translateX(0);
      }

      &-enter-active {
        opacity: 1;
        transform: translateX(0);
      }

      &-exit-active {
        opacity: 0;
        transform: translateX(-50vw);
      }
    }

    &-Right {
      &-enter,
      &-exit-active {
        opacity: 0;
        transform: translateX(50vw);
      }

      &-exit {
        opacity: 1;
        transform: translateX(0);
      }

      &-enter-active {
        opacity: 1;
        transform: translateX(0);
      }

      &-exit-active {
        opacity: 0;
        transform: translateX(50vw);
      }
    }
  }
}
