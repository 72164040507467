// FONTS
@mixin font--medium() {
  font-family: Satoshi, sans-serif;
  font-weight: 500;
  letter-spacing: -0.04em;
}

@mixin font--bold() {
  font-family: Satoshi, sans-serif;
  font-weight: 700;
  letter-spacing: -0.008em;
}

@mixin font--black() {
  font-family: Satoshi, sans-serif;
  font-weight: 900;
  letter-spacing: -0.03em;
}

// Colors
$color__blue: #0033ff;
$color__gray: #eae8e3;
$color__purple: #301944;
$color__white: #ffffff;
$color__yellow: #ffcc00;

// Font sizes
@mixin fontsize--small() {
  font-size: 12px;
  line-height: 16px;
}

@mixin fontsize--normal() {
  font-size: 16px;
  line-height: 22px;
}

@mixin fontsize--headline() {
  font-size: 36px;
  line-height: 49px;
}
