@import '../global/shared';

.List {
  padding: 50px 0;

  &__Title {
    margin-bottom: 25px;
  }

  &__Search {
    max-width: 450px;
    margin: 0 auto 35px;
  }

  &__NoResults {
    text-align: center;

    b {
      @include font--black;
    }
  }

  &__Bubbles {
    display: grid;
    column-gap: 12px;
    row-gap: 20px;

    & > * {
      grid-column: span 2;
    }

    // 3 bzw. 2
    @include responsive(0, 767px) {
      grid: auto / repeat(6, 1fr);

      & > *:nth-child(5n-1) {
        grid-column: 2 / 4;
      }
    }

    // 4 bzw. 3
    @include responsive(768px, 991px) {
      grid: auto / repeat(8, 1fr);

      & > *:nth-child(7n-2) {
        grid-column: 2 / 4;
      }
    }

    // 5 bzw. 4
    @include responsive(992px, 1199px) {
      grid: auto / repeat(10, 1fr);

      & > *:nth-child(9n-3) {
        grid-column: 2 / 4;
      }
    }

    // 6 bzw. 5
    @include responsive(1200px, 99999px) {
      grid: auto / repeat(12, 1fr);

      & > *:nth-child(11n-4) {
        grid-column: 2 / 4;
      }
    }
  }
}
